
require('../bootstrap');
require('slick-carousel');
import AOS from 'aos';

jQuery(document).ready(function myFunction() {
    var $ = require("jquery");
    window.jQuery = $;
    require("@fancyapps/fancybox");
    $.ajaxSetup({
        headers: {
            'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content'),
            'Access-Control-Allow-Origin': '*',
        }
    });

    if ($('#trigger-open-cart').length) {
        $('#carrito').animate({ width: 'toggle' });
        $('.bgcarrito').fadeToggle();
    }

    $('.not-logged').on('click', function(e) {
        $('#login-button').trigger('click');
        // alert('Necesitas iniciar sesión para comprar o agregar algo al carrito');
        e.preventDefault();
    });

    // $('.mas, .menos').on('click', function () {
    //     var input = $(this);
    //     var id = input.data('pivot');
    //     var quantity = input.val();
    //     console.log(quantity);
    //     if ($(this).hasClass('mas')) {
    //         quantity++;
    //         input.val(quantity);
    //     } else {
    //         quantity--;
    //         input.val(quantity);
    //     }
    //     console.log(quantity);
    //     $.ajax({
    //         url: '/cart/update/' + id + '/' + quantity,
    //         type: 'GET',
    //         success: function (result) {
    //             console.log(result);
    //             input.closest('.quantity').siblings('.description').find('.subtotal-p').text(result['subtotal']);
    //             $('#total').text(result['total']);
    //         },
    //         error: function (result) {
    //             console.log("Error", result);
    //         }
    //     });
    // });

    $('.mas, .menos').on('click', function () {
        var $button = $(this);
        var $inputCartQuantity = $button.siblings('.cantidad-num');
        var id = $button.data('pivot');
        var quantity = parseInt($inputCartQuantity.val());
        // quantity = intval(inputQ.value);
        // console.log($inputCartQuantity[0], $inputCartQuantity[0].value, $inputCartQuantity.val());

        if ($button.hasClass('mas')) {
        quantity++;
        $inputCartQuantity.val(quantity);
        //   $inputCartQuantity[0].value = quantity;
        //   inputQ.value = quantity;
        //   $inputCartQuantity.siblings('.menos').val(quantity);
        } else if($button.hasClass('menos')){
        quantity--;
        if(quantity < 0){
            quantity = 0;
        }
        $inputCartQuantity.val(quantity);
        //   inputQ.value = quantity;
        //   $inputCartQuantity.siblings('.mas').val(quantity);
        }

        // console.log(quantity);
        if(quantity > 0){
            $.ajax({
                url: '/cart/update/' + id + '/' + quantity,
                type: 'GET',
                success: function success(result) {
                // console.log(result);
                var $carrito2 = $button.closest('.carrito1').siblings('.carrito2');
                // $button.closest('.quantity').siblings('.description').find('.subtotal-p').text(result['subtotal']);
                $carrito2.find('#subtotal').text(result['subtotal']);
                $carrito2.find('#total').text(result['total']);
                $carrito2.find('#quantity').text(result['quantity']);
                // $('#quantity').html(result['total']);

                // $('#total').text(result['total']);
                },
                error: function error(result) {
                console.log("Error", result);
                }
            });
        }
        else{
            window.location.href = '/cart/delete/' + id;
        }
    });

    $('a.page-scroll').bind('click', function (event) {
        $(".navbar-collapse").collapse("hide");
        var $anchor = $(this);
        $('html, body').stop().animate({
            scrollTop: $($anchor.attr('href')).offset().top - 60
        }, 1500, 'easeInOutExpo');
        event.preventDefault();
    });

    AOS.init({
        duration: 1200,
        disable: true,
        offset: 40,
        delay: 300
    });

    $('#direccion-facturacion').change(function () {
        $(".direccion-facturacion").slideToggle();
    });

    $('#informacion-facturacion').change(function () {
        $(".informacion-facturacion").slideToggle();
    });

    $('#abrir-carrito').click(function () {
        $('#carrito').animate({ width: 'toggle' });
        $('.bgcarrito').fadeToggle();
    });

    $('.cerrar-carrito').click(function () {
        $('#carrito').animate({ width: 'toggle' });
        $('.bgcarrito').fadeToggle();
    });

    $('.responsive').slick({
        dots: false,
        arrows: false,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 3000,
        pauseOnHover: false
    });

    $('.publicidad1').slick({
        dots: false,
        arrows: false,
        infinite: true,
        speed: 4000,
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 3000,
        pauseOnHover: false
    });

    $('.publicidad2').slick({
        dots: false,
        arrows: false,
        infinite: true,
        speed: 4000,
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 3000,
        pauseOnHover: false
    });


    $('.publicidad4').slick({
        dots: false,
        arrows: false,
        infinite: true,
        speed: 4000,
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 3000,
        pauseOnHover: false
    });


    $('.mas-fotos-slider').slick({
        dots: false,
        arrows: true,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
    autoplay: true,
        autoplaySpeed: 3000,
    pauseOnHover: false
    });

    $('.slider-revista').slick({
        dots: true,
        arrows: false,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 4000,
        pauseOnHover: false
    });


    $(".navbar-toggler").click(function () {
        $("#menu-sitio").toggleClass("menu-abierto");
    });

    $(".abrir-cerrar-busqueda").click(function () {
        $("#busqueda").fadeToggle();
    });

    var myVar;

        myVar = setTimeout(showPage, 800);

    function showPage() {
        jQuery('#loader').fadeOut(500);
    }
});


(function ($) {

    $(document).ready(function () {
        if ($(this).scrollTop() > 200) {
            $('.navbar').addClass('scrolled-nav');
        } else {
            $('.navbar').removeClass('scrolled-nav');
        }
    });
});

// fade in .navbar
$(function () {
    $(window).scroll(function () {
        // set distance user needs to scroll before we fadeIn navbar
        if ($(this).scrollTop() > 200) {
            $('.navbar').addClass('scrolled-nav');
        } else {
            $('.navbar').removeClass('scrolled-nav');
        }
    });
}(jQuery));
